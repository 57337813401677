import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import ParticleBackground from "./Particles";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Landing from "./Landing";
import LobbyBrowser from "./LobbyBrowser";
import axios from "axios";
import Privacy from "./Privacy";
import ModeList from "./ModeList";

export default function App() {
    const [playerCount, setPlayerCount] = useState("0");

    useEffect(() => {
        axios.get("https://play.skeld.net/api/playercount").then((response: any) => {
            setPlayerCount(response.data);
        });
    }, []);

    return (
        <div className="bg-background text-foreground min-vh-100">
            <ParticleBackground />
            <Container className="p-5">
                <h1 style={{ fontSize: "7em" }}>skeld.net</h1>
                {/*<p style={{ color: "red", fontWeight: "bold", fontSize: "1.25em" }}>
                    IN GAME LOBBY BROWSER CURRENTLY BROKEN - 30th March 2025
                </p>*/}
                {playerCount == "0" ? (
                    ""
                ) : (
                    <p>There are currently {playerCount} people playing</p>
                )}
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/codes" element={<LobbyBrowser />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route
                        path="/https"
                        element={
                            <Button href="amongus://init?servername=skeld.net&serverport=22030&serverip=https://play.skeld.net&usedtls=false"></Button>
                        }
                    />
                </Routes>
                <br />
                <ModeList />
            </Container>
        </div>
    );
}
