import { Card } from "react-bootstrap";

export default function Android() {
    return (
        <div>
            <Card.Title>Easy Installation</Card.Title>
            <Card.Text style={{ fontSize: "1em"}}>
                <ol>
                    <li>
                        Download our launcher{" "}
                        <a
                            href="https://play.google.com/store/apps/details?id=net.skeld.switcher"
                            target={"_blank"}
                        >
                            here
                        </a>{" "}
                    </li>
                    <li>
                        {" "}
                        Open Among Us and wait until it loads the main menu{" "}
                    </li>
                    <li>
                        Press the home button to leave Among Us, but don't close
                        it in the app switcher
                    </li>
                    <li>Open the skeld.net launcher and press play</li>
                    <li>Watch the advertisement and close it when it's done</li>
                    <li>
                        After the advertisement is done, the launcher will
                        switch to Among Us with the server set to skeld.net!
                    </li>
                    <li>Enjoy skeld.net!</li>
                </ol>

                <h3 style={{ fontSize: "1.5em"}}>Manual Installation</h3>
                <ol>
                    <li>MAKE SURE AMONG US IS CLOSED</li>
                    <li>
                        Download <a href="https://play.google.com/store/apps/details?id=com.marc.files">https://play.google.com/store/apps/details?id=com.marc.files</a> and open it. <br/>
                        Press the 3 lines in the top left corner.<br/>
                        Press on your phone.<br/>
                        Go into Android {">"} data {">"} com.innersloth.spacemafia {">"} files<br/>
                        Delete the regionInfo.json file
                    </li>

                    <li>
                        Download this <a href="https://api.skeld.net/regionInfo">regionInfo.json</a> file and DO NOT open it.<br/>
                        Open the new files app we downloaded in Part 1.<br/>
                        Go into downloads folder and long press on the regionInfo.json file.<br/>
                        Press the 3 dots in the top right corner and click "Move to..."<br/>
                        Press the 3 lines in the top left corner.<br/>
                        Press your phone.<br/>
                        Press the folder called "Android"<br/>
                        Press "Move"
                    </li>

                    <li>
                        Press the 3 lines in the top left corner.<br/>
                        Press on your phone.<br/>
                        Press the folder called "Android".<br/>
                        In here should be your regionInfo.json file.<br/>
                        Long press on the regionInfo.json file.<br/>
                        Press and drag on the regionInfo.json file to enter "drag mode".<br/>
                        Drag your finger over the "data" folder.<br/>
                        This should move that file into the data folder.<br/>
                        Press the 3 dots in the top right corner.<br/>
                        Press "Sort by..."<br/>
                        Press "Modified (Oldest first)"<br/>
                        Scroll to the bottom of this menu.<br/>
                        Drag the regionInfo.json file into the com.innersloth.spacemafia folder.<br/>
                        Open the "com.innersloth.spacemafia" folder and drag regionInfo.json into the "files" folder.
                    </li>

                    <li>
                        Open Among Us."
                    </li>
                </ol>
            </Card.Text>
        </div>
    );
}
